import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Learners.css";
import { Link } from "react-router-dom/dist";
import { useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import { BsThreeDotsVertical } from "react-icons/bs";
import { learnerList } from "../redux/learner";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import helper from "../../helper/axiosHelper";
import { FaDownload } from "react-icons/fa";
import * as XLSX from "xlsx";

const Learners = () => {
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [selectedLearner, setSelectedLearner] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShowview(false);
  let [refresher, setRefresher] = useState(true);
  let dispatch = useDispatch();
  let navigate = useNavigate();

  const [learnerListData, setLearnerListData] = useState([]);
  const [learnerListDataCopy, setLearnerListDataCopy] = useState([]);
  const [qusetionName, setQusetionName] = useState("");
  const [answerName, setAnswerName] = useState("");
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(0);
  const [corporates, setCorporates] = useState([]);
  const [selectedLearnerType, setSelectedLearnerType] = useState("");

  const list_corporates = async () => {
    try {
      const response = await helper.postData(
        helper.baseUrl() + "getCorporates"
      );
      setCorporates(response.data.corporates);
    } catch (error) {
      console.error("Error fetching corporates:", error);
    }
  };

  // Call list_corporates when the component mounts
  useEffect(() => {
    list_corporates();
  }, []);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = learnerListData.slice(indexOfFirstItem, indexOfLastItem);
  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      learnerList((resp) => {
        if (resp.status) {
          setLearnerListData(resp.data);
          setLearnerListDataCopy(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const viewLearner = (id) => {
    sessionStorage.setItem("LearnerId", id);
    navigate("/view_learners");
  };

  const editLearner = (id) => {
    sessionStorage.setItem("LearnerId", id);
    navigate("/edit_learners");
  };

  const filterBySearch = (event) => {
    const query = event.target.value;
    var updatedlist = [...learnerListDataCopy];
    var updatedList = updatedlist.filter((item) => {
      return item.email.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
    setLearnerListData(updatedList);
  };
  const filterBySelect = (event) => {
    const query = event.target.value;
    var updatedlist = [...learnerListDataCopy];

    // Check if the selection is in the corporates list
    const isCorporate = corporates.some((corp) => corp._id === query);

    if (query !== "") {
      if (isCorporate) {
        // Filter learners by matching the corporate ID
        var updatedList = updatedlist.filter((item) => {
          return item.corporate === query;
        });
        setLearnerListData(updatedList);
        setSelectedLearnerType("corporate");
      } else {
        var updatedList = updatedlist.filter((item) => {
          return item.learnerType === query;
        });
        setLearnerListData(updatedList);
        setSelectedLearnerType(query);
      }
    } else {
      setLearnerListData(learnerListDataCopy);
      setSelectedLearnerType("");
    }
  };
  const filterByCollege = (event) => {
    const query = event.target.value;
    var updatedlist = [...learnerListDataCopy];

    var updatedList = updatedlist.filter((item) => {
      return item.collegeName.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });

    setLearnerListData(updatedList);
  };

  const handleCheckboxChange = (learnerId) => {
    setSelectedLearner((prevSelected) => {
      if (prevSelected?.includes(learnerId)) {
        return prevSelected.filter((id) => id !== learnerId);
      } else {
        return [...(prevSelected || []), learnerId];
      }
    });
  };
  const handleSendMail = () => {
    const selectedLearnerIds = selectedLearner;
    sessionStorage.setItem("selectedLearnerIds", selectedLearnerIds.join(","));
    navigate("/send_mail_edu");
  };

  const handleDownloadExcel = () => {
    // Create data for excel
    const excelData = learnerListData.map((item) => ({
      Name: item.name || "N/A",
      Email: item.email || "N/A",
      "College Name": item.collegeName || "N/A",
      "Learner Type": item.learnerType || "N/A",
      Status: item.isActive ? "Active" : "Inactive",
    }));

    // Create worksheet
    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Learners");

    // Generate filename based on selected type
    let filename = "learners_data.xlsx";
    if (selectedLearnerType === "corporate") {
      const selectedCorporate = corporates.find(
        (corp) =>
          corp._id === document.querySelector('select[name="corporate"]')?.value
      );
      if (selectedCorporate) {
        filename = `${selectedCorporate.corporateName}_learners.xlsx`;
      }
    } else if (selectedLearnerType) {
      filename = `${selectedLearnerType}_learners.xlsx`;
    }

    // Save file
    XLSX.writeFile(wb, filename);
  };

  return (
    <>
      <div className="Categorypage">
        <div className="PageTit ">
          <div className="container">
            <div className="row">
              <div className="col-6">
                <h3>Learners List</h3>
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/learners">Learners</Link>
                </p>
              </div>
              <div className="col-6"></div>
            </div>
          </div>
        </div>
        <div className="CateCont">
          <div className="container">
            <div className="row">
              <div className="CateGorTab">
                <div className="col-12">
                  <div className="searchBtNs">
                    <div className="row">
                      {selectedLearnerType === "corporate" ? (
                        <>
                          <div className="col-lg-5 col-md-6">
                            <div className="BtNSSearcH">
                              <InputGroup className="mb-3">
                                <Form.Control
                                  placeholder="Search by email"
                                  aria-describedby="basic-addon2"
                                  onChange={filterBySearch}
                                />
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                >
                                  <BsSearch />
                                </Button>
                              </InputGroup>
                            </div>
                            <div className="col-lg-12 col-md-6 BtNSSearcH">
                              <Form.Select
                                onChange={filterBySelect}
                                name="corporate"
                              >
                                <option value="">Select Corporate</option>
                                {corporates.map((corporate) => (
                                  <option
                                    key={corporate._id}
                                    value={corporate._id}
                                  >
                                    {corporate.corporateName}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-4 col-md-6">
                            <div className="BtNSSearcH">
                              <InputGroup className="mb-3">
                                <Form.Control
                                  placeholder="Search By Email"
                                  aria-describedby="basic-addon2"
                                  onChange={filterBySearch}
                                />
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                >
                                  <BsSearch />
                                </Button>
                              </InputGroup>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6">
                            <div className="BtNSSearcH">
                              <InputGroup className="mb-3">
                                <Form.Control
                                  placeholder="Search College Name"
                                  aria-describedby="basic-addon2"
                                  onChange={filterByCollege}
                                />
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                >
                                  <BsSearch />
                                </Button>
                              </InputGroup>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-lg-3 col-md-6">
                        <div className="BtNSSearcH">
                          <select onChange={filterBySelect}>
                            <option value="">Learners Type</option>
                            <option value="student">Student</option>
                            <option value="workingProfessional">
                              Working Professional
                            </option>
                            <option value="doctor">doctor</option>
                            <option value="corporate">Corporate</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-4">
                        <div className="BtNSSearcH mt-2 d-flex gap-2">
                          <Link
                            to="/send_mail"
                            // className="Grn-Btn"
                            style={{
                              background: "#C7F7DA",
                              border: "1px solid #107B38",
                              color: "#107B38",
                              fontWeight: "600",
                              padding: "5px 10px",
                              borderRadius: "5px",
                              fontSize: "18px",

                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textDecoration: "none",
                              height: "40px",
                              cursor: "pointer",
                              transition: "all 0.3s ease",
                              "&:hover": {
                                background: "#107B38",
                                color: "#fff",
                              },
                            }}
                            onClick={handleSendMail}
                          >
                            Send Mail
                          </Link>
                          {learnerListData.length > 0 && (
                            <Button
                              variant="outline-success"
                              onClick={handleDownloadExcel}
                              title="Download Excel"
                            >
                              <FaDownload />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <input type="checkbox" />
                      </th>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>College Name</th>
                      <th>Learners Type</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedLearner.includes(item._id)}
                            onChange={() => handleCheckboxChange(item._id)}
                          />
                        </td>
                        <td>{indexOfFirstItem + i + 1}</td>
                        <td>{item?.name || "N/A"}</td>
                        <td>{item?.email || "N/A"}</td>
                        <td>{item?.collegeName || "N/A"}</td>
                        <td>{item?.learnerType || "N/A"}</td>
                        <td>
                          <button
                            className="GrnActn"
                            style={{
                              background:
                                item?.isActive === true ? "#C7F7DA" : "#F8D9D9",
                              border:
                                item?.isActive === true
                                  ? "1px solid #107B38"
                                  : "1px solid #AB0A0A",
                            }}
                          >
                            {item?.isActive === true ? "Active" : "InActive"}
                          </button>
                        </td>
                        <td>
                          <div className="d-flex ">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list">
                                <li onClick={() => viewLearner(item._id)}>
                                  View
                                </li>
                                <li onClick={() => editLearner(item._id)}>
                                  Edit
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Question</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      autoFocus
                      value={qusetionName}
                      onChange={(e) => setQusetionName(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  autoFocus
                  style={{ height: "150px" }}
                  value={answerName}
                  onChange={(e) => setAnswerName(e.target.value)}
                />
              </Form.Group>
              <div className="d-flex">
                <button className="Grn-Btn Half" type="submit">
                  add
                </button>
                <button className="WHt-Btn Half" type="button">
                  cancel
                </button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        {/* view modal */}
        <Modal show={showview} onHide={handleClose1}>
          <Modal.Header closeButton>
            <Modal.Title>Edit FAQ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Question</Form.Label>
                    <Form.Control type="text" placeholder="" autoFocus />
                  </Form.Group>
                </div>
              </div>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder=""
                  autoFocus
                  style={{ height: "150px" }}
                />
              </Form.Group>
              <div className="d-flex justify-content-center">
                <button className="Grn-Btn Half">add</button>
                <button className="WHt-Btn Half">cancel</button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </div>

      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={Math.ceil(learnerListData.length / itemsPerPage)}
        onPageChange={handlePageChange}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        subContainerClassName={"pages pagination"}
        containerClassName={"pagination-containers  green-pagination"}
        activeClassName={"active-page"}
        forcePage={currentPage}
      />
    </>
  );
};
export default Learners;
