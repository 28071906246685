import { useState, useEffect } from "react";
import "./Learners.css";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { learnerInfo, learnerStatusChange } from "../redux/learner";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { TbFileUpload } from "react-icons/tb";

const Editlearners = () => {
  const [learnerInfoData, setLearnerInfoData] = useState(null); // Start as null
  const [learnerInfoDataCopy, setLearnerInfoDataCopy] = useState(null); // Start as null
  const [statusData, setStatusData] = useState();
  const [previewImage, setPreviewImage] = useState(null);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    let learnerID = sessionStorage.getItem("LearnerId");
    let data = { id: learnerID };
    dispatch(loader(true));
    dispatch(
      learnerInfo(data, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          setLearnerInfoData(resp.data); // Set the data directly
          setLearnerInfoDataCopy(resp.data); // Assuming resp.data is not an array
          setPreviewImage(resp.data.profilePic || null); // Safely access profilePic
        } else {
          toast.error(resp.message);
        }
      })
    );
  }, [dispatch]);

  const statusChange = (e) => {
    const selectedValue = e.target.value;
    const isActive = selectedValue === "true";
    setStatusData(isActive);
    setLearnerInfoDataCopy((prev) => ({ ...prev, isActive })); // Update only the status
  };

  const updateStatus = () => {
    let learnerID = sessionStorage.getItem("LearnerId");
    dispatch(loader(true));

    let data = {
      id: learnerID,
      isActive: statusData,
    };

    dispatch(
      learnerStatusChange(data, (resp) => {
        dispatch(loader(false));
        if (resp.status) {
          toast.success(resp.message);
          navigate("/learners");
        } else {
          toast.error(resp.message);
        }
      })
    );
  };

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
      setPreviewImage(null); // Reset if no file is selected
    }
  };

  // Check if learnerInfoData is loaded
  if (!learnerInfoData) {
    return <div>Loading...</div>; // Or a loading spinner
  }

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/learners">Learners</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">{learnerInfoData.fullName || "N/A"}</Link>
              </p>
              <h3>{learnerInfoData.fullName || "N/A"}</h3>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="CorsELiSTab">
                <div className="row">
                  <div className="col-md-12 col-lg-6 col-xl-6 lrnImg">
                    <div className="ThumBnail lrnrs">
                      <div className="upLoad-learner">
                        <label
                          htmlFor="file-upload"
                          className="custom-file-upload "
                        ></label>
                        <label htmlFor="imgupload">
                          <input
                            type="file"
                            id="imgupload"
                            onChange={handleProfilePic}
                            style={{ display: "none" }}
                          />
                          <i style={{ display: "none" }}>
                            <TbFileUpload className="uploadIcon" />
                          </i>
                          <img
                            src={
                              previewImage ||
                              "https://cdn.landesa.org/wp-content/uploads/default-user-image.png"
                            }
                            className="img-fluid"
                            alt="User Profile"
                            style={{
                              width: "200px",
                              marginTop: "-54px",
                              height: "150px",
                            }}
                          />
                        </label>
                      </div>
                    </div>
                    <div className="ViewCon">
                      <label>Status</label>
                      <br />
                      <select
                        value={learnerInfoDataCopy?.isActive ? "true" : "false"}
                        onChange={statusChange}
                      >
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </select>
                      <div className="Grn-Btnmanin flLef lrnedit">
                        <button className="Grn-Btn" onClick={updateStatus}>
                          Update Status
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-6 col-xl-6">
                    <div className="CorsELiSTab pt-4 pb-4">
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td className="CatCOlr">Name</td>
                            <td>{learnerInfoData.fullName || "N/A"}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Learner Type</td>
                            <td>{learnerInfoData.userType || "N/A"}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Email Address</td>
                            <td>{learnerInfoData.email || "N/A"}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Mobile Number</td>
                            <td>{learnerInfoData.phoneNumber || "N/A"}</td>
                          </tr>
                          {learnerInfoData.userType === "corporate" ? (
                            <>
                              <tr>
                                <td className="CatCOlr">Employee Code</td>
                                <td>{learnerInfoData.empCode || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Region</td>
                                <td>{learnerInfoData.region || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">State</td>
                                <td>{learnerInfoData.state || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Head Quarter</td>
                                <td>{learnerInfoData.headqQuarter || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Designation</td>
                                <td>{learnerInfoData.designation || "N/A"}</td>
                              </tr>
                            </>
                          ) : null}
                          {learnerInfoData.userType === "doctor" ? (
                            <>
                              <tr>
                                <td className="CatCOlr">Specialization</td>
                                <td>
                                  {learnerInfoData.specialization || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Degree Certificate</td>
                                <td>
                                  {learnerInfoData.degreeCertificate ? (
                                    <a
                                      href={learnerInfoData.degreeCertificate}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-primary"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : null}
                          {(learnerInfoData.userType === "Student" ||
                            learnerInfoData.userType ===
                              "workingProfessional") && (
                            <>
                              <tr>
                                <td className="CatCOlr">Name of the College</td>
                                <td>
                                  {learnerInfoData.academic?.collegeName ||
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Year of the College</td>
                                <td>
                                  {learnerInfoData.academic?.yearOfCollege ||
                                    "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editlearners;
