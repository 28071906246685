import "./App.css";
import SideBar from "./components/Sidebar/SideBar";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Profile from "../src/components/topbar/Profile";
import Dashboard from "./pages/Dasboard/Dashboard";
import Login from "./pages/Login/Login";
import Category from "./pages/Category/Category";
import Testimonial from "./pages/Testimonials/Testimonial";
import Faq from "./pages/FAQ/Faq";
import Learners from "./pages/Learners/Learners";
import Experts from "./pages/Experts/Experts";
import Addexperts from "./pages/Experts/Addexperts";
import Editexperts from "./pages/Experts/Editexperts";
import Blogs from "./pages/Blogs/Blogs";
import Educators from "./pages/Educator/Educator";
import Adminlist from "./pages/Adminlist/Adminlist";
import Adminrole from "./pages/Adminlist/Adminrole";
import Permission from "./pages/Adminlist/Permissionlist";
import Purchase from "./pages/Purchase/Purchase";
import SubPurchase from "./pages/Purchase/SubPurchase";
import Addblog from "./pages/Blogs/Addblog";
import Editblog from "./pages/Blogs/Editblog";
import Courses from "./pages/Course/Course";
import Coursebundle from "./pages/Course/Coursebundle";
import Sendmail from "./pages/Learners/Sendmail";
import SendmailEdu from "./pages/Educator/SendmailEdu";
import Addcourse from "./pages/Course/Addcourse";
import Viewcourse from "./pages/Course/Viewcourse";
import Editcourse from "./pages/Course/Editcourse";
import Viewlearners from "./pages/Learners/Viewlearners";
import Changepassword from "./pages/Changepasword/Changepassword";
import Vieweducator from "./pages/Educator/Vieweducator";
import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import Editlearners from "./pages/Learners/Editlearner";
import { useDispatch } from "react-redux";
import { isLogin } from "../src/pages/redux/common";
import Banner from "./pages/Banners/Banner";
import Subscription from "./pages/Banners/Subscription";
import Liveprogram from "./pages/Banners/Liveprogram";
import Addbanner from "./pages/Banners/Addbanner";
import Addsubscription from "./pages/Banners/Addsubscription";
import Addliveprogram from "./pages/Banners/Addliveprogram";
import jwt_decode from "jwt-decode";

import { useNavigate } from "react-router-dom";
import Viewexperts from "./pages/Experts/ViewExperts";
import Editbanner from "./pages/Banners/EditBanner";
import Viewbanner from "./pages/Banners/viewBanner";
import VideoTestimonial from "./pages/VideoTestimonials/VideoTestimonial";
import EditSubScriptionbanner from "./pages/Banners/EditSubscritionBanner";
import EditLiveProgramBanner from "./pages/Banners/EditLiveProgram";
import RatingsandReviews from "./pages/Ratings&Reviews/Ratings&Reviews";
import Coupons from "./pages/Coupons/Coupons";
import SubscriptionPlan from "./pages/Subscription/Subscription";
import ViewAdminUser from "./pages/Adminlist/ViewAdminUser";
import JobList from "./pages/JobList/JobList";
import JobApplicants from "./pages/JobApplicants/JobApplicants";
import TrainingPurchase from "./pages/Purchase/TrainingPurchase";
import Topbar from "./components/topbar/Topbar";
import NotFound from "./components/NotFound";
import Corporate from "./pages/Corporate/Corporate";
function App() {
  const navigate = useNavigate();

  let [authStatus, setAuthStatus] = useState(false);
  let authToggler = useSelector((state) => state.apiReducer.authToggler);
  const isLoginSel = useSelector((state) => state.apiReducer.isLogin);
  const loaderStatus = useSelector((state) => state.apiReducer.loader);
  let dispatch = useDispatch();

  let trueRoutes = (
    <>
      <TailSpin
        height="100"
        width="80"
        color="#107B38"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass="tailSpin"
        visible={loaderStatus}
      />
      <SideBar>
        <Topbar />
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/category" element={<Category />} />
          <Route path="corporate" element={<Corporate />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/testimonials" element={<Testimonial />} />
          <Route path="/videotestimonials" element={<VideoTestimonial />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/reviews" element={<RatingsandReviews />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/learners" element={<Learners />} />
          <Route path="/list_to_experts" element={<Experts />} />
          <Route path="/add_experts" element={<Addexperts />} />
          <Route path="/edit_experts" element={<Editexperts />} />
          <Route path="view_experts" element={<Viewexperts />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/educators" element={<Educators />} />
          <Route path="/admin_user" element={<Adminlist />} />
          <Route path="/view_admin_user" element={<ViewAdminUser />} />
          <Route path="/admin_role" element={<Adminrole />} />
          <Route path="/permission_list/:id" element={<Permission />} />
          <Route path="/purchase_list" element={<Purchase />} />
          <Route path="/sub_purchase_list" element={<SubPurchase />} />
          <Route
            path="/training_purchase_list"
            element={<TrainingPurchase />}
          />
          <Route path="/add_blog" element={<Addblog />} />
          <Route path="/edit_blog/:id" element={<Editblog />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/course_bundle" element={<Coursebundle />} />
          <Route path="/send_mail" element={<Sendmail />} />
          <Route path="/send_mail_edu" element={<SendmailEdu />} />
          <Route path="/add_course" element={<Addcourse />} />
          <Route path="/view_course/:id" element={<Viewcourse />} />
          <Route path="/edit_course/:id" element={<Editcourse />} />
          <Route path="/view_learners" element={<Viewlearners />} />
          <Route path="/edit_learners" element={<Editlearners />} />
          <Route path="/change_password" element={<Changepassword />} />
          <Route path="/view_educator" element={<Vieweducator />} />
          <Route path="/banner" element={<Banner />} />
          <Route path="/subscription_banner" element={<Subscription />} />
          <Route path="/live_program" element={<Liveprogram />} />
          <Route path="/add_banner" element={<Addbanner />} />
          <Route path="/edit_banner/:id" element={<Editbanner />} />
          <Route
            path="/edit_subscritionbanner/:id"
            element={<EditSubScriptionbanner />}
          />
          <Route
            path="/edit_liveprogrambanner/:id"
            element={<EditLiveProgramBanner />}
          />
          <Route path="/view_banner/:id" element={<Viewbanner />} />
          <Route path="/subscription_plan" element={<SubscriptionPlan />} />
          <Route path="/add_subscription" element={<Addsubscription />} />
          <Route path="/add_liveprogram" element={<Addliveprogram />} />
          <Route path="/job_list" element={<JobList />} />
          <Route path="/job_applicants" element={<JobApplicants />} />
          <Route
            path="/login"
            element={
              isLoginSel ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/"
            element={
              isLoginSel ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </SideBar>
      <ToastContainer />
    </>
  );

  let falseRoutes = (
    <Routes>
      <Route
        path="/"
        element={
          isLoginSel ? <Navigate to="/dashboard" /> : <Navigate to="/login" />
        }
      />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/change_password" element={<Changepassword />} />
    </Routes>
  );

  useEffect(() => {
    if (localStorage.getItem("adminOAuth")) {
      dispatch(isLogin(true));
      setAuthStatus(true);
    } else {
      setAuthStatus(false);
    }
  });
  setInterval(() => {
    AuthVerifyComponent();
  }, 1000);

  const AuthVerifyComponent = () => {
    if (localStorage.getItem("adminOAuth")) {
      const jwt_Token_decoded = jwt_decode(localStorage.getItem("adminOAuth"));
      if (jwt_Token_decoded.exp * 1000 < Date.now()) {
        localStorage.clear();
        navigate("/");
        dispatch(isLogin(false));
        toast.error("Session expired.Please login to proceed");
      }
    }

    return <div></div>;
  };
  return (
    <>
      {/* <Login/> */}
      {authStatus ? trueRoutes : falseRoutes}
    </>
  );
}

export default App;
