import { TbFileUpload } from "react-icons/tb";
import "./Banners.css";
import { Link, useNavigate, useParams } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { editBanner, viewBanner } from "../redux/banners";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const Editbanner = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let params = useParams();
  const [viewData, setViewData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [imageFile, setImageFile] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [videoUrlValue, setVideoUrlValue] = useState("");
  useEffect(() => {
    let Id = params.id;
    let data = {};
    data["bannerId"] = Id;
    dispatch(loader(true));
    dispatch(
      viewBanner(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          setSelectedOption(resp.data.bannerFor);
          setPreviewImage(resp.data.thumbnail);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);
  const {
    formState: { errors: errors },
    handleSubmit: handleSubmit,
  } = useForm({ mode: "onBlur" });

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    var arr = {};

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        arr[key] = object1[key];
      }
    }
    return arr;
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const bannerAdd = (data) => {
    var compareVal = {
      videoUrl: videoUrlValue,
      bannerFor: selectedOption,
    };
    var result = shallowEqual(compareVal, viewData);
    let formData = new FormData();
    formData.append("bannerId", params.id);
    if (result.videoUrl !== "") {
      formData.append("videoUrl", videoUrlValue);
    }
    if (result !== "") {
      formData.append("bannerFor", selectedOption);
    }
    if (imageFile !== "") {
      formData.append("thumbnail", imageFile);
    }
    dispatch(loader(true));
    dispatch(
      editBanner(formData, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          navigate("/banner");
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };
  const cancelBanner = () => {
    navigate("/banner");
  };

  const handleProfilePic = (event) => {
    const selectedFile = event.target.files[0];
    setImageFile(selectedFile);

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    } else {
    }
  };
  const videourlChange = (event) => {
    setVideoUrlValue(event.target.value);
  };
  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3>Edit Banner</h3>
              <p>
                <Link to="/dashboard">Dasboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Content Update</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/banner">Banners</Link>
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>Edit Banner</h4>
              </div>
              <div className="ExpertSBacKG">
                <form onSubmit={handleSubmit(bannerAdd)}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="ThumBnail banner">
                        <div className="upLoad upLoaded">
                          <label
                            htmlFor="imgupload"
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              <span className="text-center">
                                <TbFileUpload className="uploadIcons" />
                              </span>
                            </div>
                            <input
                              type="file"
                              id="imgupload"
                              onChange={handleProfilePic}
                              style={{ display: "none" }}
                            />
                            <div
                              style={{
                                display: previewImage ? "none" : "block",
                              }}
                            >
                              <i style={{ display: "none" }}>
                                <TbFileUpload className="uploadIcon" />
                              </i>
                              <p>Click here to upload</p>
                            </div>
                            {previewImage && (
                              <img
                                src={previewImage}
                                className="img-fluid upLoad"
                                alt="User Profile"
                              />
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 pt-3">
                      <div className="SHortsForm banner">
                        <label>Video URL</label>
                        <br></br>
                        <input
                          type="text"
                          className="FormShorts"
                          name="videoUrl"
                          defaultValue={viewData.videoUrl}
                          onChange={videourlChange}
                        />
                        {errors.videoUrl && (
                          <small style={{ color: "red" }}>
                            {errors.videoUrl.message}
                          </small>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            name="profession"
                            value="Student"
                            checked={selectedOption === "Student"}
                            onChange={handleOptionChange}
                          />
                          <label className="professionbanner">Student</label>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            value="Working"
                            name="Working"
                            checked={selectedOption === "Working"}
                            onChange={handleOptionChange}
                          />
                          <label className="professionbanner">Working</label>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            value="doctor"
                            name="doctor"
                            checked={selectedOption === "doctor"}
                            onChange={handleOptionChange}
                          />
                          <label className="professionbanner">doctor</label>
                        </div>
                        <div className="col-lg-3 col-md-3">
                          <input
                            className="bannerinput"
                            type="radio"
                            value="Corporate"
                            name="Corporate"
                            checked={selectedOption === "Corporate"}
                            onChange={handleOptionChange}
                          />
                          <label className="professionbanner">Corporate</label>
                        </div>
                        {errors.bannerFor && (
                          <small style={{ color: "red", float: "left" }}>
                            {errors.bannerFor.message}
                          </small>
                        )}
                      </div>

                      <div className="row">
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button className="Grn-Btn" type="submit">
                              Update
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-4">
                          <div className="SHortsForm1">
                            <button
                              className="WHt-Btn"
                              type="button"
                              onClick={cancelBanner}
                            >
                              cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Editbanner;
