import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Learners.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { learnerInfo } from "../redux/learner";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { TbFileUpload } from "react-icons/tb";

const Viewlearners = () => {
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [learnerInfoData, setLearnerInfoData] = useState(null); // Start with null
  const [previewImage, setPreviewImage] = useState(null); // Start with null
  const [loading, setLoading] = useState(true); // Loading state
  let dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShowview(false);

  useEffect(() => {
    const learnerID = sessionStorage.getItem("LearnerId");
    const data = { id: learnerID };
    dispatch(loader(true));
    dispatch(
      learnerInfo(data, (resp) => {
        if (resp.status) {
          setLearnerInfoData(resp.data); // Set the entire data object
          setPreviewImage(resp.data.profilePic || null); // Use optional chaining
        } else {
          toast.error(resp.message);
        }
        dispatch(loader(false));
        setLoading(false); // Set loading to false after fetching data
      })
    );
  }, [dispatch]);

  // Loading state
  if (loading) return <div>Loading...</div>;

  // If learnerInfoData is not available, show a message
  if (!learnerInfoData) {
    return <div>No learner data available.</div>;
  }

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/learners">Learners</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">{learnerInfoData.fullName || "N/A"}</Link>
              </p>
              <h3>{learnerInfoData.fullName || "N/A"}</h3>
            </div>
            <div className="col-6"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="CorsELiSTab">
                <div className="row">
                  <div className="col-md-12 col-lg-4 col-xl-4">
                    <div className="ThumBnail lrnrs">
                      <div className="upLoad-learner">
                        <label
                          htmlFor="file-upload"
                          className="custom-file-upload-learner "
                        ></label>
                        <label htmlFor="imgupload">
                          <input
                            type="file"
                            id="imgupload"
                            style={{ display: "none" }}
                            disabled
                          />
                          <i style={{ display: "none" }}>
                            <TbFileUpload className="uploadIcon" />
                          </i>
                          {previewImage ? (
                            <img
                              src={previewImage}
                              className="img-fluid"
                              alt="User Profile"
                              style={{
                                width: "190px",
                                marginTop: "26px",
                                height: "130px",
                              }}
                            />
                          ) : (
                            <p style={{ color: "black" }}>Image Not Uploaded</p>
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="ViewCon">
                      <label>Status</label>
                      <br />
                      <select
                        value={learnerInfoData.isActive ? "Active" : "InActive"}
                        disabled
                      >
                        <option value="">Select</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-8 col-xl-8">
                    <div className="CorsELiSTab pt-4 pb-4">
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td className="CatCOlr">Name</td>
                            <td>{learnerInfoData.fullName || "N/A"}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Learner Type</td>
                            <td>{learnerInfoData.userType || "N/A"}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Email Address</td>
                            <td>{learnerInfoData.email || "N/A"}</td>
                          </tr>
                          <tr>
                            <td className="CatCOlr">Mobile Number</td>
                            <td>{learnerInfoData.phoneNumber || "N/A"}</td>
                          </tr>
                          {learnerInfoData.userType === "corporate" ? (
                            <>
                              <tr>
                                <td className="CatCOlr">Employee Code</td>
                                <td>{learnerInfoData.empCode || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Region</td>
                                <td>{learnerInfoData.region || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">State</td>
                                <td>{learnerInfoData.state || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Head Quarter</td>
                                <td>{learnerInfoData.headqQuarter || "N/A"}</td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Designation</td>
                                <td>{learnerInfoData.designation || "N/A"}</td>
                              </tr>
                            </>
                          ) : null}
                          {learnerInfoData.userType === "doctor" ? (
                            <>
                              <tr>
                                <td className="CatCOlr">Specialization</td>
                                <td>
                                  {learnerInfoData.specialization || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Degree Certificate</td>
                                <td>
                                  {learnerInfoData.degreeCertificate ? (
                                    <a
                                      href={learnerInfoData.degreeCertificate}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-primary"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                              </tr>
                            </>
                          ) : null}
                          {(learnerInfoData.userType === "Student" ||
                            learnerInfoData.userType ===
                              "workingProfessional") && (
                            <>
                              <tr>
                                <td className="CatCOlr">Name of the College</td>
                                <td>
                                  {learnerInfoData.academic?.collegeName ||
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Year of the College</td>
                                <td>
                                  {learnerInfoData.academic?.yearOfCollege ||
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Degree of Stream</td>
                                <td>
                                  {learnerInfoData.academic?.degreeOfStream ||
                                    "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Pincode</td>
                                <td>
                                  {learnerInfoData.address?.pinCode || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">City</td>
                                <td>
                                  {learnerInfoData.address?.city || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">State</td>
                                <td>
                                  {learnerInfoData.address?.state || "N/A"}
                                </td>
                              </tr>
                              <tr>
                                <td className="CatCOlr">Country</td>
                                <td>
                                  {learnerInfoData.address?.country || "N/A"}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control type="text" placeholder="" autoFocus />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                autoFocus
                style={{ height: "150px" }}
              />
            </Form.Group>
            <div className="d-flex">
              <button className="Grn-Btn Half">add</button>
              <button className="WHt-Btn Half">cancel</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* View modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control type="text" placeholder="" autoFocus />
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                autoFocus
                style={{ height: "150px" }}
              />
            </Form.Group>
            <div className="d-flex">
              <button className="Grn-Btn Half">add</button>
              <button className="WHt-Btn Half">cancel</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Viewlearners;
