import { createSlice } from "@reduxjs/toolkit";
import Helper from "../../helper/axiosHelper";
const baseUrl = Helper.baseUrl();

const initialState = {
  //   firstSignUp: {},
};

export const educatorSlice = createSlice({
  name: "login",
  initialState,
  reducers: {},
});

export const { firstSignUp } = educatorSlice.actions;

export default educatorSlice.reducer;

export const educatorSendMail =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "educator/sendEmail",
      data
    ).then((response) => response.data);
    callback(result);
  };
export const educatorInfo =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(
      baseUrl + "educator/educatorInfo",
      data
    ).then((response) => response.data);
    callback(result);
  };
export const educatorDelete =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.postData(baseUrl + "educator/delete", data).then(
      (response) => response.data
    );
    callback(result);
  };
export const educatorEmailIds =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "educator/educators").then(
      (response) => response.data
    );
    callback(result);
  };

export const ActiveEducators =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(
      baseUrl + "educator/activeEducators"
    ).then((response) => response.data);
    callback(result);
  };
export const profileInfo =
  (callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + "profile_info").then(
      (response) => response.data
    );
    callback(result);
  };

export const educatordetailView =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.getData(baseUrl + `educator/detail/${data}`).then(
      (response) => response.data
    );
    callback(result);
  };

export const educatorStatusChange =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "educator/updateStatus",
      data
    ).then((response) => response.data);
    callback(result);
  };

export const educatorApproveChange =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(
      baseUrl + "educator/approve",
      data
    ).then((response) => response.data);
    callback(result);
  };
export const passwordChange =
  (data, callback = () => {}) =>
  async (dispatch) => {
    var result = await Helper.patchData(baseUrl + "changePassword", data).then(
      (response) => response.data
    );
    callback(result);
  };
