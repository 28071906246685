import "./Banners.css";
import { Link, useParams } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { viewBanner } from "../redux/banners";
import { useDispatch } from "react-redux";
import { loader, isLogin } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const Viewbanner = () => {
  let dispatch = useDispatch();
  let params = useParams();
  const [viewData, setViewData] = useState({});
  useEffect(() => {
    let Id = params.id;
    let data = {};
    data["bannerId"] = Id;
    dispatch(loader(true));
    dispatch(
      viewBanner(data, (resp) => {
        if (resp.status) {
          setViewData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, []);

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <h3>View Banner</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="">Content Update</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/banner">Banners</Link>
              </p>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="TittlE">
                <h4>View Banner</h4>
              </div>
              <div className="ExpertSBacKG">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="ThumBnail banner">
                      <img src={viewData?.thumbnail} className="upLoad" />
                    </div>
                  </div>
                  <div className="col-lg-12 pt-3">
                    <div className="SHortsForm banner">
                      <form>
                        <label>Video URL</label>
                        <br></br>
                        <input
                          type="text"
                          className="FormShorts"
                          value={viewData?.videoUrl}
                          readOnly
                        />
                      </form>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 col-md-3">
                        <input
                          className="bannerinput"
                          type="radio"
                          name="profession"
                          value="Student"
                          checked={viewData?.bannerFor === "Student"}
                        />
                        <label className="professionbanner">Student</label>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <input
                          className="bannerinput"
                          type="radio"
                          name="profession"
                          value="Working"
                          checked={viewData?.bannerFor === "Working"}
                        />
                        <label className="professionbanner">Working</label>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <input
                          className="bannerinput"
                          type="radio"
                          name="profession"
                          value="doctor"
                          checked={viewData?.bannerFor === "doctor"}
                        />
                        <label className="professionbanner">doctor</label>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <input
                          className="bannerinput"
                          type="radio"
                          name="profession"
                          value="Corporate"
                          checked={viewData?.bannerFor === "Corporate"}
                        />
                        <label className="professionbanner">Corporate</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Viewbanner;
