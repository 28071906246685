import "./Adminlist.css";
import { Link, useParams } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { useEffect, useState } from "react";
import {
  admin_assign_permissions,
  admin_roles_and_permissions,
  create_permissions,
} from "../redux/login";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
const Permission = () => {
  const rolesId = useParams();
  const dispatch = useDispatch();
  const [rolesNPermissionsInfo, setRolesNPermissionsInfo] = useState([]);
  let [refresher, setRefresher] = useState(true);
  const [show, setShow] = useState(false);
  const [section, setSection] = useState("");
  const [view, setView] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    let data = {};
    data["roleId"] = rolesId.id;
    dispatch(loader(true));
    dispatch(
      admin_roles_and_permissions(data, (resp) => {
        if (resp.status) {
          const findPersInfo = resp.data.find((x) => x.roleId === rolesId.id);
          setRolesNPermissionsInfo(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
      })
    );
  }, [refresher, rolesId.id]);

  const handle_assign_permissions = (sectionId, permission) => {
    // Create a copy of rolesNPermissionsInfo before modifying it
    const updatedPermissions = rolesNPermissionsInfo.map((item) => {
      if (item._id === sectionId) {
        const updatedItem = { ...item, permissions: [...permission] }; // Create a copy of the permissions array
        return updatedItem;
      }
      return item;
    });

    setRolesNPermissionsInfo(updatedPermissions);

    const data = {
      _id: sectionId,
      permissions: permission,
    };
    dispatch(loader(true));
    dispatch(
      admin_assign_permissions(data, (result) => {
        if (result.status) {
          setRefresher(!refresher);
          toast.success(result.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(result.message);
        }
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      roleId: rolesId.id,
      section,
      permissions: [],
    };

    if (view) {
      data.permissions.push("view");
    }
    if (edit) {
      data.permissions.push("edit");
    }
    if (deletePermission) {
      data.permissions.push("delete");
    }

    dispatch(loader(true));
    dispatch(
      create_permissions(data, (result) => {
        if (result.status) {
          toast.success(result.message);
          setSection("");
          setView(false);
          setEdit(false);
          setDeletePermission(false);
          setRefresher(!refresher);
          handleClose();
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(result.message);
        }
      })
    );
  };

  const capitalizeAndRemoveUnderscores = (str) => {
    // Check if str is undefined or null, and handle it gracefully
    if (!str) {
      return ""; // or any default value you prefer
    }

    // Split the string by underscores and capitalize each word
    const words = str
      .split("_")
      .map((word) => {
        // Capitalize the first letter of each word
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" "); // Join the words back together with a space

    return words;
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Assign Permissions</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/admin_role">Admin Roles</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/permission_list">Admin Permission</Link>
              </p>
            </div>
            <div className="col-6">
              <div className="Grn-Btnmanin">
                <button className="Grn-Btn" onClick={handleShow}>
                  Add Permissions
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Role</th>
                    <th>View</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {rolesNPermissionsInfo.map((item) => (
                    <tr key={item._id}>
                      <td>{capitalizeAndRemoveUnderscores(item.section)}</td>
                      <td className="ddFlAR">
                        <div className="OnOfF">
                          <Form>
                            <Form.Check
                              type="switch"
                              id={`custom-switch-view-${item._id}`}
                              checked={item.permissions.includes("view")}
                              onChange={() =>
                                handle_assign_permissions(
                                  item._id,
                                  item.permissions.includes("view")
                                    ? []
                                    : ["view"]
                                )
                              }
                            />
                          </Form>
                        </div>
                      </td>
                      <td className="ddFlAR">
                        <div className="OnOfF">
                          <Form>
                            <Form.Check
                              type="switch"
                              id={`custom-switch-edit-${item._id}`}
                              checked={item.permissions.includes("edit")}
                              onChange={() =>
                                handle_assign_permissions(
                                  item._id,
                                  item.permissions.includes("edit")
                                    ? item.permissions.filter(
                                        (permission) => permission !== "edit"
                                      )
                                    : [...item.permissions, "edit"]
                                )
                              }
                            />
                          </Form>
                        </div>
                      </td>
                      <td className="ddFlAR">
                        <div className="OnOfF">
                          <Form>
                            <Form.Check
                              type="switch"
                              id={`custom-switch-delete-${item._id}`}
                              checked={item.permissions.includes("delete")}
                              onChange={() =>
                                handle_assign_permissions(
                                  item._id,
                                  item.permissions.includes("delete")
                                    ? item.permissions.filter(
                                        (permission) => permission !== "delete"
                                      )
                                    : [...item.permissions, "delete"]
                                )
                              }
                            />
                          </Form>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/*Add Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Permissions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="sectionDropdown">
              <Form.Label>Section</Form.Label>
              <Form.Control
                as="select"
                value={section}
                onChange={(e) => setSection(e.target.value)}
              >
                <option value="">Select Section</option>
                <option value="dashboard">Dashboard</option>
                <option value="corporate">Corporates</option>
                <option value="category">Category</option>
                <option value="courses">Courses</option>
                <option value="listen_to_experts">Listen To Experts</option>
                <option value="educators">Educators</option>
                <option value="blog">Blog</option>
                <option value="testimonial">Testimonial</option>
                <option value="faq">Faq</option>
                <option value="user_management">User Management</option>
                <option value="jobs">Job List</option>
                <option value="banner">Banner</option>
                <option value="subscription_banner">Subscription Banner</option>
                <option value="live_program_banner">Live Program Banner</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="viewCheckbox">
              <Form.Check
                type="checkbox"
                label="View"
                checked={view}
                onChange={() => setView(!view)}
              />
            </Form.Group>
            <Form.Group controlId="editCheckbox">
              <Form.Check
                type="checkbox"
                label="Edit"
                checked={edit}
                onChange={() => setEdit(!edit)}
              />
            </Form.Group>
            <Form.Group controlId="deleteCheckbox">
              <Form.Check
                type="checkbox"
                label="Delete"
                checked={deletePermission}
                onChange={() => setDeletePermission(!deletePermission)}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <button type="submit" className="Grn-Btn Half">
                Add
              </button>
              <button
                type="button"
                className="WHt-Btn Half"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Permission;
