import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import "./Course.css";
import { Link } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import {
  CoursesStatus,
  categoryList,
  courseListCb,
  courseListDelete,
  editCourse,
} from "../redux/courses";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loader } from "../redux/common";
import ReactPaginate from "react-paginate";

const Courses = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let [refresher, setRefresher] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [toggled, setToggled] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [localSearchValue, setLocalSearchValue] = useState("");
  const [eduSearchValue, setEduSearchValue] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const itemsPerPage = 20;
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [filteredEducators, setFilteredEducators] = useState([]);
  const userPermissions = JSON.parse(localStorage.getItem("userPermissions"));
  const userType = localStorage.getItem("userType");

  const isViewPermissionAvailable = (section, permissions) => {
    const userPermission = userPermissions.find(
      (permission) => permission.section === section
    );
    return userPermission && userPermission.permissions.includes("view");
  };

  const isEditPermissionAvailable = (section, permissions) => {
    const userPermission = userPermissions.find(
      (permission) => permission.section === section
    );
    return userPermission && userPermission.permissions.includes("edit");
  };
  const isDeletePermissionAvailable = (section, permissions) => {
    const userPermission = userPermissions.find(
      (permission) => permission.section === section
    );
    return userPermission && userPermission.permissions.includes("delete");
  };
  useEffect(() => {
    const fetchData = () => {
      let requestData = {
        pageNo: currentPage + 1,
      };
      if (localSearchValue) {
        requestData.title = localSearchValue;
      }
      if (eduSearchValue) {
        requestData.educatorSearch = eduSearchValue;
      }
      if (selectedCategory) {
        requestData.selectCategory = selectedCategory;
      }
      if (selectedStatus) {
        if (selectedStatus === "approved") {
          requestData.approved = 1;
        } else if (selectedStatus === "pending") {
          requestData.pending = 1;
        }
      }
      if (selectedPrice) {
        if (selectedPrice === "free") {
          requestData.price = "Free";
        } else if (selectedPrice === "paid") {
          requestData.price = "Paid";
        }
      }
      dispatch(
        courseListCb(requestData, (resp) => {
          dispatch(loader(true));
          if (resp.status) {
            const eduinfo = resp.data.map((ele) => ele.educators);
            setCourseList(resp.data);
            setFilteredCourses(resp.data);
            setFilteredEducators(eduinfo);
            setTotalPages(resp.totalPages);
            dispatch(loader(false));
          } else {
            setFilteredCourses([]);
            dispatch(loader(false));
          }
          dispatch(loader(false));
        })
      );
    };

    fetchData();

    dispatch(
      categoryList((resp) => {
        if (resp.status) {
          setCategoryData(resp.data);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [
    refresher,
    localSearchValue,
    eduSearchValue,
    selectedCategory,
    selectedStatus,
    selectedPrice,
  ]);

  const editAndNavigateToFourthTab = (courseId) => {
    navigate(`/edit_course/${courseId}`, { state: { activeTab: "first" } });
  };

  const fetchCoursesByPage = (pageNumber) => {
    setCurrentPage(pageNumber);

    // Fetch data based on page number
    const data = { pageNo: pageNumber + 1 };
    fetchData(data);
  };

  const handleSearchClick = () => {
    fetchData({ title: localSearchValue });
  };
  const handleSearchClickEdu = () => {
    fetchData({ educatorSearch: eduSearchValue });
  };
  const deleteCourse = (id, i) => {
    let data = {};
    data["courseId"] = id;
    dispatch(loader(true));
    dispatch(
      courseListDelete(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          fetchCoursesByPage(currentPage);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const changeStatus = (id, index) => {
    // Check if the course is already approved
    if (filteredCourses[index].isApproved) {
      // If it's already approved, do nothing
      return;
    }

    let data = {};
    let value = {
      isApproved: !toggled[index], // Toggle the isApproved status
    };
    data["courseId"] = id;
    dispatch(loader(true));
    // Update the toggled array to reflect the new isApproved status
    const updatedToggled = [...toggled];
    updatedToggled[index] = value.isApproved;
    setToggled(updatedToggled);

    dispatch(
      CoursesStatus(data, (response) => {
        if (response.status) {
          toast.success(response.message);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(response.message);
        }
        dispatch(loader(false));
        setRefresher(!refresher);
      })
    );
  };

  const fetchData = (data) => {
    dispatch(loader(true));
    dispatch(
      courseListCb(data, (resp) => {
        if (resp.status) {
          const eduinfo = resp.data.map((ele) => ele.educators);
          setFilteredEducators(eduinfo);
          setCourseList(resp.data);
          setFilteredCourses(resp.data);
          setTotalPages(resp.totalPages);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
        }
        dispatch(loader(false));
      })
    );
  };

  const filterByCategory = (category) => {
    const filtered = courseList.filter(
      (course) => course.category === category
    );
    setFilteredCourses(filtered);
  };

  const filterByStatus = (status) => {
    const filtered = courseList.filter((course) => {
      if (status === "approved") {
        return course.isApproved;
      } else if (status === "pending") {
        return !course.isApproved;
      }
      return true; // No status filter
    });
    setFilteredCourses(filtered);
  };

  const filterByPrice = (price) => {
    const filtered = courseList.filter((course) => {
      if (price === "free") {
        return course.price === "Free";
      } else if (price === "paid") {
        return course.price === "Paid";
      }
      return true; // No price filter
    });
    setFilteredCourses(filtered);
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-5">
              <h3>Course List</h3>
              {userType !== "educator" ? (
                <p>
                  <Link to="/dashboard">Dashboard</Link>{" "}
                  <i>
                    <AiOutlineRight />
                  </i>{" "}
                  <Link to="/courses">Manage Courses</Link>
                </p>
              ) : (
                <p>
                  <Link to="/courses">Manage Courses</Link>
                </p>
              )}
            </div>
            <div className="col-6 col-md-7">
              <div className="Grn-Btnmanin">
                <Link to="/add_course" className="Grn-Btn">
                  Add New Course
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <div className="col-12">
                <div className="searchBtNs">
                  <div className="row">
                    <div className="col-lg-2 col-md-3">
                      <div className="BtNSSearcH">
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Search By Title"
                            aria-describedby="basic-addon2"
                            value={localSearchValue}
                            onChange={(e) =>
                              setLocalSearchValue(e.target.value)
                            }
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={handleSearchClick}
                          >
                            <BsSearch />
                          </Button>
                        </InputGroup>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <div className="BtNSSearcH">
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Search By Educator"
                            aria-describedby="basic-addon2"
                            value={eduSearchValue}
                            onChange={(e) => setEduSearchValue(e.target.value)}
                          />
                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={handleSearchClickEdu}
                          >
                            <BsSearch />
                          </Button>
                        </InputGroup>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                      <div className="BtNSSearcH">
                        <select
                          value={selectedCategory}
                          onChange={(e) => {
                            setSelectedCategory(e.target.value);
                            filterByCategory(e.target.value);
                          }}
                        >
                          <option value="">Category</option>
                          {categoryData.map((category) => (
                            <option key={category.id} value={category._id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <div className="BtNSSearcH">
                        <select
                          value={selectedStatus}
                          onChange={(e) => {
                            setSelectedStatus(e.target.value);
                            filterByStatus(e.target.value);
                          }}
                        >
                          <option>Verification Status</option>
                          <option value="approved">Approved</option>
                          <option value="pending">Pending</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-1">
                      <div className="BtNSSearcH">
                        <select
                          value={selectedPrice}
                          onChange={(e) => {
                            setSelectedPrice(e.target.value);
                            filterByPrice(e.target.value);
                          }}
                        >
                          <option>Price</option>
                          <option value="free">Free</option>
                          <option value="paid">Paid</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Course Name</th>
                    <th>Category</th>
                    <th>Educator</th>
                    <th>Duration</th>
                    <th>Price</th>
                    <th>Level</th>
                    <th>Verification</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCourses?.length === 0 ? (
                    <tr>
                      <td colSpan="9">No courses found.</td>
                    </tr>
                  ) : (
                    filteredCourses?.map((item, i) => (
                      <tr key={item._id}>
                        <td>{i + 1 + currentPage * itemsPerPage}</td>
                        <td>{item?.title}</td>
                        <td>{item?.category}</td>
                        <td>{item?.educators}</td>
                        <td>{item?.duration}</td>
                        <td>₹{item?.price}</td>
                        <td>{item?.level}</td>
                        <td>
                          <button
                            className={item.isApproved ? "GrnActn" : "RedDel"}
                            onClick={() => changeStatus(item._id, i)}
                          >
                            {item.isApproved ? "Approved" : "Pending"}
                          </button>
                        </td>
                        <td>
                          <div className="d-flex">
                            <div className="Options pt-1">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul className="hidden-list crsLi">
                                {isViewPermissionAvailable(
                                  "courses",
                                  userPermissions
                                ) && (
                                  <li
                                    onClick={() =>
                                      navigate(`/view_course/${item._id}`)
                                    }
                                  >
                                    View
                                  </li>
                                )}
                                {isEditPermissionAvailable(
                                  "courses",
                                  userPermissions
                                ) && (
                                  <li
                                    onClick={() =>
                                      editAndNavigateToFourthTab(item._id)
                                    }
                                  >
                                    Edit
                                  </li>
                                )}
                                {isDeletePermissionAvailable(
                                  "courses",
                                  userPermissions
                                ) && (
                                  <li onClick={() => deleteCourse(item._id, i)}>
                                    Delete
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <div className="pagination-container">
        <ReactPaginate
          pageCount={totalPages}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          onPageChange={(selectedPage) =>
            fetchCoursesByPage(selectedPage.selected)
          }
          containerClassName={"pagination-containers  green-pagination"}
          activeClassName={"active-page"}
        />
      </div>
    </div>
  );
};

export default Courses;
