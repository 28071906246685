import { useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "./Experts.css";
import { Link, useNavigate } from "react-router-dom/dist";
import { AiOutlineRight } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  shortsList,
  deleteShortsList,
  changeStatusShorts,
} from "../redux/shorts";
import { useDispatch } from "react-redux";
import { loader } from "../redux/common";
import { toast } from "react-toastify";
import { useEffect } from "react";

const Experts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showview, setShowview] = useState(false);
  const [shortListData, setShortListData] = useState([]);
  let [refresher, setRefresher] = useState(true);
  const [checked, setChecked] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShowview(false);
  const handleShow1 = () => setShowview(true);

  useEffect(() => {
    dispatch(loader(true));
    dispatch(
      shortsList((resp) => {
        if (resp.status) {
          setShortListData(resp.data);
          var arr = [];
          for (var i = 0; i < resp.data.length; i++) {
            arr.push(resp.data[i].isActive);
          }
          setChecked(arr);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  }, [refresher]);

  const viewShortList = (id) => {
    sessionStorage.setItem("ShortListId", id);
    navigate("/view_experts");
  };

  const editShortList = (id) => {
    sessionStorage.setItem("ShortListId", id);
    navigate("/edit_experts");
  };

  const deletesSortList = (id) => {
    let data = {};
    data["shortsId"] = id;

    dispatch(loader(true));
    dispatch(
      deleteShortsList(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  const changeStatus = (id, index) => {
    let data = {};
    let value = {
      isActive: !checked[index],
    };
    data["shortsId"] = id;
    data["isActive"] = value.isActive;

    dispatch(loader(true));
    dispatch(
      changeStatusShorts(data, (resp) => {
        if (resp.status) {
          toast.success(resp.message);
          setRefresher(!refresher);
          dispatch(loader(false));
        } else {
          dispatch(loader(false));
          toast.error(resp.message);
        }
      })
    );
  };

  return (
    <div className="Categorypage">
      <div className="PageTit ">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h3>Shorts List</h3>
              <p>
                <Link to="/dashboard">Dashboard</Link>{" "}
                <i>
                  <AiOutlineRight />
                </i>{" "}
                <Link to="/list_to_experts">Listen to Experts</Link>
              </p>
            </div>
            <div className="col-6">
              <div className="Grn-Btnmanin">
                <Link
                  to="/add_experts"
                  className="Grn-Btn"
                  onClick={handleShow}
                >
                  add Shorts
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="CateCont">
        <div className="container">
          <div className="row">
            <div className="CateGorTab">
              <Table striped hover className="shortsTable">
                <thead>
                  <tr className="">
                    <th>#</th>
                    <th>Thumbnail</th>
                    <th>Title</th>
                    <th>Video URL</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {shortListData.map((item, index) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td
                        className="img-container text-left"
                        style={{ width: "65px" }}
                      >
                        <img src={item.thumbnail} className="img-fluid" />
                      </td>
                      <td className="DivWidth faq titl text-left">
                        {item.title}
                      </td>
                      <td className="DivWidth faq text-left">
                        {item.videoUrl}
                      </td>
                      <td className="actLft">
                        <div className="d-flex">
                          <div className="">
                            <div className="OnOfF ">
                              <Form>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  onChange={() => changeStatus(item._id, index)}
                                  checked={checked[index]}
                                />
                              </Form>
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="Options pt-1 ">
                              <i>
                                <BsThreeDotsVertical />
                              </i>
                              <ul class="hidden-list shrtLi">
                                <li onClick={() => viewShortList(item._id)}>
                                  View
                                </li>
                                <li onClick={() => editShortList(item._id)}>
                                  Edit
                                </li>
                                <li
                                  onClick={() =>
                                    deletesSortList(item._id, index)
                                  }
                                >
                                  Delete
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control type="text" placeholder="" autoFocus />
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                autoFocus
                style={{ height: "150px" }}
              />
            </Form.Group>
            <div className="d-flex">
              <button className="Grn-Btn Half">add</button>
              <button className="WHt-Btn Half">cancel</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      {/* view modal */}
      <Modal show={showview} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Edit FAQ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Question</Form.Label>
                  <Form.Control type="text" placeholder="" autoFocus />
                </Form.Group>
              </div>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder=""
                autoFocus
                style={{ height: "150px" }}
              />
            </Form.Group>
            <div className="d-flex">
              <button className="Grn-Btn Half">add</button>
              <button className="WHt-Btn Half">cancel</button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default Experts;
